import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import SEO from '../components/seo'
import SpaceBetweenText from '../ui/SpaceBetweenText'

const BlogSection = styled.section`
  display: grid;
  grid-gap: 4em;
`

const BlogCard = ({ article, className }) => (
  <article key={article.id} className={className}>
    <Img
      fluid={article.frontmatter.thumbnail.childImageSharp.fluid}
      className="blog-thumbnail"
    />
    <div>
      <h2>
        <Link to={article.fields.slug}>{article.frontmatter.title}</Link>
      </h2>
      <SpaceBetweenText as="p" className="blog-info">
        <span>{new Date(article.frontmatter.date).toLocaleDateString()}</span>
        <span>{article.timeToRead} min</span>
      </SpaceBetweenText>
      <p>{article.excerpt}</p>
    </div>
  </article>
)

const StyledBlogCard = styled(BlogCard)`
  display: grid;
  grid-template-columns: 450px 1fr;
  align-items: flex-start;
  border: 5px solid ${p => p.theme.colors.gray};
  padding: 2em;
  gap: 2em;
  transform: rotate3d(0, 0, 0.5, -2deg);
  transition: border 0.5s, transform 0.5s;
  .blog-thumbnail {
    align-self: stretch;
  }

  a {
    line-height: inherit;
  }

  > div > h2 > a {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .blog-info {
    font-weight: bold;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
  :nth-child(odd) {
    transform: rotate3d(0, 0, 0.5, 2deg);
  }

  :hover,
  :focus-within {
    border: 5px solid ${p => p.theme.colors.primary};
    transform: rotate3d(0, 0, 1, 0deg);
  }
`

function BlogPage({ data, location }) {
  return (
    <>
      <SEO
        title="Blog"
        description="Levi Butcher's Blog"
        path={location.pathname}
      />
      <h1>Blog</h1>
      <BlogSection>
        {data.allMdx.edges.map(({ node }) => (
          <StyledBlogCard article={node} key={node.id} />
        ))}
      </BlogSection>
    </>
  )
}

export const query = graphql`
  {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { fields: { slug: { regex: "/^/blog//" } } }
    ) {
      edges {
        node {
          id
          timeToRead
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 850) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default BlogPage
